import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "index": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "fonts-tester": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "anim": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "anim/anim-1": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/anim-2": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "anim/anim-3": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "anim/anim-4": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/110": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "anim/12111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/121111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/11112": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "anim/1211111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/111121": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "anim/12111111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/121111111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/1211111111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/12111111111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/111122": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "anim/121111111111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/1211111111111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/12111111111111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/121111111111111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/1211111111111111": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "anim/1101": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "app/1": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/2": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/3": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "app/4": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "app/5": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/6": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "app/7": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/8": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/9": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "app/10": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/11": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "app/12": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/13": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/14": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/15": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/16": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "app/17": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/18": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/19": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/20": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/21": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "app/22": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "app/23": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "app/analyzing-the-answers": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "q/q1": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q2": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q3": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "q/q4": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "q/q5": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q6": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "q/q7": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q8": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q9": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "q/q10": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q11": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "q/q12": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q13": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q14": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q15": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q16": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "q/q17": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q18": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q19": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q20": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q21": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "q/q22": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "q/q23": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "q/analyzing-the-answers-fb": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "pmax": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
